/* eslint-disable max-lines */
import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { AnimatePresence, m } from 'framer-motion';
import { Root as PortalRoot } from '@radix-ui/react-portal';
import { ACCESSORIES_PATH, getGiftCardCopy, getGiftCardFormCopy, LOCALE_DICT, MEDIUM_WIDTH } from '@constants';
import {
	Body,
	Breadcrumbs,
	Button,
	ChecklistItem,
	Flex,
	GiftCardAmountSelector,
	GiftCardSelector,
	Heading,
	Input,
	Paragraph,
	Popover,
	SidebarLayout,
	StyledWordEmphasizer,
	Title,
} from '@components';
import { NormalizedVariant } from '@ts/product';
import { fadeLinear, slideFromPaddedBottom } from '@utils/motions';
import { useIsMobile, useTranslation } from '@utils/hooks';
import variables from '@styles/export.module.scss';
import { useCartContext } from '@context';
import styles from './GiftCardSidebar.module.scss';

type GiftCardSidebarProps = {
	selectedVariant: NormalizedVariant;
	variants: NormalizedVariant[];
	setSelectedVariant: Dispatch<SetStateAction<NormalizedVariant>>;
	name: string;
};

const getTranslatedTexts = translator => {
	return {
		addToCart: translator('add-to-cart'),
		addToCartPlus: translator('add-to-cart-plus'),
		howDoesItWork: translator('how-does-it-work'),
		giftCardEmailInstruction: translator('gift-card-email-instruction'),
		giftCardChooseDesign: translator('gift-card-choose-design'),
		giftCardChooseAmount: translator('gift-card-choose-amount'),
		giftCardGuidance: translator('gift-card-guidance'),
		leaveLittleNote: translator('leave-a-little-note'),
		giftCardTip: translator('gift-card-tip'),
		moreInfoIncoming: translator('more-info-incoming'),
		giftCardValidationAllIncluded: translator('gift-card-validation-all-included'),
		giftCardValidationSimple: translator('gift-card-validation-simple'),
		giftCardValidationCodes: (yourSingleWord: string) => translator('gift-card-validation-codes', { yourSingleWord }),
		yourSingleWord: translator('gift-card-your-single-word'),
	};
};

const GiftCardSidebar = ({ selectedVariant, variants, setSelectedVariant, name }: GiftCardSidebarProps) => {
	const ctaRef = useRef<HTMLButtonElement>(null);
	const isMobile = useIsMobile({ maxWidth: MEDIUM_WIDTH });
	const { pathname: path, locale } = useRouter();
	const currencyCode = LOCALE_DICT[locale].currencyCode;
	const { handleCartAdd, isCartMutating } = useCartContext();
	const [giftCardDetails, setGiftCardDetails] = useState({
		_recipientName: '',
		_noteMessage: '',
		_variantImage: selectedVariant.image.url ?? '',
	});
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);
	const GIFT_CARD_COPY = getGiftCardCopy(locale);
	const GIFT_CARD_FORM_COPY = getGiftCardFormCopy(locale);
	// TODO - this is kind of hacky, is there a better way to create a Set of objects?
	const prices = Array.from(new Set(variants.map(variant => variant.price.amount))).map(amount => ({
		amount,
		currencyCode: currencyCode,
	}));
	const maxCount = 250;

	const message = (
		<div>
			<ChecklistItem caption={GIFT_CARD_COPY.TWENTY_FIVE} />
			<br />
			<ChecklistItem caption={GIFT_CARD_COPY.FIFTY} />
			<br />
			<ChecklistItem caption={GIFT_CARD_COPY.HUNDRED} />
			<br />
			<ChecklistItem caption={GIFT_CARD_COPY.TWO_HUNDRED} />
		</div>
	);

	const handleAddToCart = () => {
		const giftCardAttributes = Object.entries(giftCardDetails).reduce((acc, cur) => {
			if (cur[1] === '') return acc;

			acc.push({
				key: cur[0],
				value: cur[1],
			});
			return acc;
		}, []);

		handleCartAdd(
			[
				{
					variant: selectedVariant,
					customAttributes: [
						...giftCardAttributes,
						{
							key: '_collectionPath',
							value: `${ACCESSORIES_PATH}/`,
						},
					],
				},
			],
			true,
			ctaRef
		);
	};

	return (
		<div>
			<SidebarLayout.Sidebar className={styles.sidebar} pdpOptimizationStyle>
				<SidebarLayout.Content className={styles.sidebarContent}>
					<Breadcrumbs path={path} />
					<Heading tag='h1'>{name}</Heading>
					<Title className={styles.howItWorks}>{translations.howDoesItWork}</Title>
					<Paragraph>{translations.giftCardEmailInstruction}</Paragraph>
					<Title className={styles.decideDesign}>{translations.giftCardChooseDesign}</Title>
					<GiftCardSelector
						variants={variants}
						prices={prices}
						onChange={variant => {
							const selectedVariantWithPrice = variants.find(
								originalVariant =>
									variant.image.id === originalVariant.image.id &&
									originalVariant.price.amount === selectedVariant.price.amount
							);
							setSelectedVariant(selectedVariantWithPrice);
							setGiftCardDetails({ ...giftCardDetails, _variantImage: variant.image.url });
						}}
						selectedVariant={selectedVariant}
					/>
					<Flex className={styles.pickPerfectAmount} justify='between'>
						<Title style={{ marginBottom: '0' }}>{translations.giftCardChooseAmount}</Title>
						<Popover extraClasses={styles.tooltip} message={message}>
							<span>
								<Paragraph>{translations.giftCardGuidance}</Paragraph>
							</span>
						</Popover>
					</Flex>
					<GiftCardAmountSelector
						prices={prices}
						onChange={newPrice => {
							const selectedVariantWithPrice = variants.find(
								variant => variant.image.id === selectedVariant.image.id && variant.price.amount === newPrice
							);
							setSelectedVariant({ ...selectedVariantWithPrice });
						}}
						selectedVariant={selectedVariant}
						locale={locale}
					/>
					<Title className={styles.whosItFor}>{GIFT_CARD_FORM_COPY.title}</Title>
					<Input
						onChange={({ target }) => {
							setGiftCardDetails({ ...giftCardDetails, _recipientName: target.value });
						}}
						{...GIFT_CARD_FORM_COPY.recipient}
					/>
					<Flex justify='between'>
						<Title>{translations.leaveLittleNote}</Title>
						<Paragraph>
							{giftCardDetails._noteMessage.length}/{maxCount}
						</Paragraph>
					</Flex>
					<Input.Multiline
						className={styles.leaveLittleNote}
						rows={3}
						maxLength={maxCount}
						onChange={({ target }) => {
							setGiftCardDetails({ ...giftCardDetails, _noteMessage: target.value });
						}}
						{...GIFT_CARD_FORM_COPY.message}
					/>
					<Body>{translations.giftCardTip}</Body>
					<Title className={styles.moreInfoIncoming}>{translations.moreInfoIncoming}</Title>
					<Paragraph>
						{translations.giftCardValidationAllIncluded}
						<br />
						<br />
						{translations.giftCardValidationSimple}
						<br />
						<br />
						<StyledWordEmphasizer
							text={translations.giftCardValidationCodes(translations.yourSingleWord)}
							emphasizedWords={translations.yourSingleWord}
							styledTexts={
								<span style={{ fontStyle: 'italic', fontWeight: 'initial' }}>{translations.yourSingleWord}</span>
							}
						/>
					</Paragraph>
				</SidebarLayout.Content>
				<Flex column gap={3} key='sticky-button__portal'>
					<PortalRoot {...(typeof document !== 'undefined' && { container: document.getElementById('__next') })}>
						<AnimatePresence>
							<m.div
								key='sticky-button__slide-from-bottom'
								className={styles.stickyButton}
								data-floating-cta={translations.addToCart}
								{...slideFromPaddedBottom}
							>
								<Flex column style={{ maxWidth: '40%' }}>
									<Heading className={styles['product-title']} tag='h6'>
										{name}
									</Heading>
									<Paragraph style={{ color: variables.gray4 }}>{`$${selectedVariant.price.amount}`}</Paragraph>
								</Flex>
								<Button
									ref={ctaRef}
									extraClasses={styles['cta']}
									label={translations.addToCartPlus}
									size={isMobile ? 'medium' : 'large'}
									color='green'
									onClick={handleAddToCart}
									disabled={isCartMutating}
									price={selectedVariant.price.amount}
								/>
							</m.div>
							<m.div className={styles.stickyGradient} {...fadeLinear} />
						</AnimatePresence>
					</PortalRoot>
				</Flex>
			</SidebarLayout.Sidebar>
		</div>
	);
};
export default GiftCardSidebar;
